.fslide-bg {
	width: 100%;
}
.fslide {
	display: block;
	position: relative;
	max-width: $pc-wide;
	padding: 20px 0;
	@include breakpoint($sm){
		padding: 10px 0;
	}
	margin: 0 auto;
	&__list {
		max-width: calc(100% - 140px);
		overflow: hidden;
		// margin: 0 auto;
		margin-left: 50px;
		@include breakpoint($sm){
			max-width: 100%;
			width: auto;
			margin: 0 90px 0 40px;
		}
		&__items {
			position: relative;
			.slick-list {
				padding: 1px 0;
			}
			.slick-slide {
				display: block;
			}
			a {
				>span{
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					margin: 0 10px;
					@include breakpoint($sm){
						margin: 0 5px 0 0;
					}
					height: 50px;
				}
				img {
					display: inline;
					vertical-align: top;
				}
			}
		}
	}
	&__ctrl {
		> * {
			position: absolute;
		}
	}
	&__btnl {
		width: 40px;
		height: 40px;
		top: 50%;
		left: 0px;
		transform: translateY(-20px);
		@include breakpoint($md) {
			left: 0;
		}
		&:focus,
		&:hover {
			.cls-1 {
				fill: #333;
			}
		}
		.cls-1 {
			fill: #d6d6d6;
		}
		.cls-2 {
			fill: #fff;
			fill-rule: evenodd;
		}
	}
	&__play,
	&__pause {
		width: 15px;
		height: 20px;
		top: 50%;
		right: 50px;
		transform: translateY(-10px);
		@include breakpoint($md) {
			right: 60px;
		}
	}
	&__btnr {
		width: 40px;
		height: 40px;
		top: 50%;
		right: 0px;
		transform: translateY(-20px);
		.cls-1 {
			fill: #d6d6d6;
		}
		.cls-2 {
			fill: #fff;
			fill-rule: evenodd;
		}
		&:focus,
		&:hover {
			.cls-1 {
				fill: #333;
			}
		}
		@include breakpoint($md) {
			right: 0;
		}
	}
}
