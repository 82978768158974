.material-icons {
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: "liga";
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	font-feature-settings: "liga";
}
.material-icons-outlined {
	font-family: "Material Icons Outlined";
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	// direction: ltr;
	-webkit-font-feature-settings: "liga";
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	font-feature-settings: "liga";
}
@mixin MaterialFontInit {
	font-weight: normal;
	font-style: normal;
	display: inline-block;
	line-height: 1;
	vertical-align: text-bottom;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	// direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: "liga";
	-webkit-font-feature-settings: 'liga';
}
@mixin font-ico($txt) {
	content: "#{$txt}";
	font-family: "Material Icons";
	@include MaterialFontInit;
}
@mixin font-ico-outline($txt) {
	content: "#{$txt}";
	font-family: 'Material Icons Outlined';
	@include MaterialFontInit;
}