@import "../../../static/fhkeng/css/default.css";
@import "../../../static/commons/scss/breakpoint"; //반응형 플러그인
@import "./base/variables";
@import "./base/color";
@import "../../../static/commons/scss/util"; //mixins
@import "./mixins/layerfixed"; //mixins
@import "./components/sprite";
@import '../../commons/scss/footer-slide';//메인푸터배너
%section {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
#section1 {
	height:800px;
	@extend %section;
	@include breakpoint($md){
		height:680px;
	}
	.section1-set {
		position: relative;
	}
}
ul,ol{
	list-style-type: none;
	padding: 0;
	margin:0;
}
.section1-set{
	height:100%;
	width:100%;
	.section1-quick {
		position: absolute;
		display: flex;
		justify-content: space-between;
		align-items: center;
		top: 316px;
		left: 14px;
		width: calc(100% - 28px);
		z-index: 99;
		@include breakpoint($lg) {
			top: 413px;
			left: calc(50% - 700px);
			max-width:  650px;
		}
		@include breakpoint(1200px 1399px) {
			top: 413px;
			left: 14px;
		}
		@include breakpoint($mdw) {
			top: 220px;
		}
		.section1__dots {
			height:4px;
			li {
				display: inline-block;
				margin-right: 6px;
				button {
					position: relative;
					width: 40px;
					height: 4px;
					// transition: all 0.2s ease;
					overflow: hidden;
					background-color: $white;
					@include breakpoint($md) {
						width: 70px;
					}
				}
				&.slick-active button::after {
					position: absolute;
					top: 0;
					left: 0;
					content: "";
					display: block;
					height: 4px;
					background-color: #a50025;
					animation: visual-button 15s;
					animation-timing-function: linear;
					// animation-delay: 0.5s;
					animation-fill-mode: forwards;
				}
			}
		}
		.section1-ctrl {
			display: flex;
			> button {
				width: 30px;
				height: 30px;
				background: 30px 30px no-repeat;
			}
			.prev {
				left: 0;
				background: url(/static/fhkeng/img/main/slide_prev.svg);
			}
			.next {
				margin-left: 5px;
				background: url(/static/fhkeng/img/main/slide_next.svg);
			}
			.section1-ctrl__play {
				position: relative;
				display: inline-block;
				margin-left: 5px;
				.pause {
					display: block;
					width: 30px;
					height: 30px;
					background: url(/static/fhkeng/img/main/slide_pause.svg);
				}
				.play {
					display: none;
					width: 30px;
					height: 30px;
					background: url(/static/fhkeng/img/main/slide_play.svg);
				}
			}
		}
	}
	.section1-visual {
		&__list {
			position: relative;
			.slick-dots {
				position: absolute;
				left: 0;
				z-index: 99;
				margin: 0;
				padding: 0;
				width: 230px;
				top: 329px;
				left: 14px;
				@include breakpoint($pc) {
					top: 426px;
					left: calc(50% - 700px);
				}
				@include breakpoint(1200px 1399px) {
					top: 426px;
					left: 0;
				}
				@include breakpoint($mdw) {
					top: 242px;
				}
				li {
					display: inline-block;
					margin-right: 6px;
					button {
						position: relative;
						width: 40px;
						height: 4px;
						transition: all 0.2s ease;
						overflow: hidden;
						background-color: $white;
						@include breakpoint($md) {
							width: 70px;
						}
					}
					&.slick-active button::after {
						position: absolute;
						top: 0;
						left: 0;
						content: "";
						display: block;
						height: 4px;
						background-color: #a50025;
						animation: visual-button 5s;
						animation-delay: 0.5s;
						animation-fill-mode: forwards;
					}
				}
			}
			> * {
				width: 100%;
				color: $white;
				.item {
					&__set {
						display: block;
						display: block;
						width: 100%;
						height: 800px;
						padding: 70px 14px;
						background: 60% center no-repeat;
						// url(/static/fhkeng/img/main/visual_slide1.jpg)
						background-size: cover;
						color: #fff;
						position: relative;
						@include breakpoint($md){
							height: 680px;
						}
						.slide_txt.txt_navy{
							color: #05224f;
						}
					}

					.bg-mobile {
						display: none;
						@include breakpoint($sm) {
							display: block;
						}
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background-position: 60% center;
						background-repeat: no-repeat;
						background-size: cover;
						z-index: -1;
					}
					.bg-pc {
						display: none;
						@include breakpoint($md) {
							display: block;
						}
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background-position: 60% center;
						background-repeat: no-repeat;
						background-size: cover;
						z-index: -1;
						// background: 60% center no-repeat;
					}

					@include breakpoint($md) {
						height: 680px;
					}
					@include breakpoint($pc) {
						background-position: center;
						.slide_txt {
							max-width: 1400px;
							margin: 50px auto 0;
							> * {
								width: 650px;
							}
						}
					}
					.sub-title {
						display: block;
						font-size: 1.5rem;
						margin-bottom: 10px;
					}
					.title {
						text-transform: uppercase;
						font-family: Verdana, Geneva, Tahoma, sans-serif;
						display: block;
						font-size: 2.625rem;
						line-height: 114%;
						margin-bottom: 10px;
						font-weight: 400;
					}
					.txt {
						display: block;
						font-size: 1.125rem;
					}
					@include breakpoint($smd) {
						.sub-title {
							font-size: 0.875rem;
						}
						.title {
							font-size: 1.5rem;
							line-height: 117%;
						}
						.txt {
							font-size: 1rem;
						}
					}
				}
			}
		}
	}
}
@keyframes visual-button {
	0%{
		width:0;
	}
	100%{
		width:100%;
	}
}
.section1-inquiry{
	position:absolute;
	top:400px;
	width:calc(100% - 28px);
	border-radius: 1.25rem 0;
	background-color: #1176bf;
	@include breakpoint($mdw){
		top:345px;
		display: flex;
	}
	@include breakpoint(min-width 1200px){
		right:0;
		top:137px;
		left:auto;
		width:380px;
		max-width: none;
	}
	@include breakpoint($smd){
		left:14px;
	}
	@include breakpoint(min-width 1400px){
		right:calc(50% - 700px);
	}
	.section1-inquiry__txt{
		padding:24px 32px;
		@include breakpoint($md){
			&::before{
				position:absolute;
				top:-22px;
				left:30px;
				content: '';
				display: block;
				width:68px;
				height:64px;
				background:url(/static/fhkeng/img/main/inquiry_deco.svg);
			}
		}
		@include breakpoint($mdw){
			flex-basis: 59%;
			flex-grow: 0;
			flex-shrink: 0;
			padding:46px 42px 30px;
		}
		@include breakpoint($pc){
			padding:53px 42px;
		}
		a{
			display: block;
			color:$white;
		}
		h2{
			display: block;
			margin-bottom: 10px;
			font-size: 1.688rem;
			font-weight: 700;
			span{
				@include breakpoint($md){ 
					display: block;
					position: absolute;
					color: #005593;
					top:-13px;
					left:41px;
					letter-spacing: -0.2rem;
					font-size: 1.7rem;
				}
			}
		}
		.inquiry-desc{
			font-family: "Nunito Sans";
			font-size: 1rem;
			line-height: 100%;
		}
	}
	.section1-inquiry__link{
		display: flex;
		border-top:1px solid #70add9;
		border-radius: 0 0 1.25rem 0;
		background-color: #0859af;
		@include breakpoint($mdw){
			flex-basis: 41%;
			border-top: 0;
		}
		a{
			width:50% ;
			padding:20px 0;
			border-right: 1px solid #70add9;
			text-align: center;
			font-size: 0.875rem;
			color:$white;
			@include breakpoint($pc){
				padding: 40px 10px 24px;
				font-size: 1rem;
			}
			@include breakpoint($mdw){
				padding: 50px 0;
			}
			&:last-of-type{
				border:0; 
			}
			&::before{
				content: '';
				width:47px;
				height:41px;
				display: block;
				margin:0 auto 8px;
				background: url(/static/fhkeng/img/main/inquiry_ico1.svg) no-repeat;
				@include breakpoint($pc){
					margin:0 auto 12px;
					width:59px;
					height:51px;
				}
			}
			&.check-my-inquiry{
				&::before{
					background:url(/static/fhkeng/img/main/inquiry_ico2.svg) no-repeat;
				}
			}
		}
		
	}
}
#section2{

}
.section2-set{
	position:relative;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin:0 auto;
	@include breakpoint($pc){
		margin-bottom: 70px;
	}
	&::before{
		content: '';
		position:absolute;
		top:-79px;
		left:10px;
		width:calc(100%  - 20px);
		display: block;
		height:80px;
		background-color: $white;
		border-radius: 0 1.875rem 0 0;
		@include breakpoint(min-width 1400px){
			left:0;
			width:calc(50% - 700px + 720px + 40px);
		}
		@include breakpoint(1200px 1399px){
			left:0;
			width:54.29%;
		}
		@include breakpoint($mdw){
			width:100%;
			left: 0;
		}
	}
	.section2-post-list{
		position:relative;
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		padding:0 14px;
		z-index: 2;
		text-align: center;
		.ie &{
			flex-basis: calc(100% - 28px);
		}
		@include breakpoint($pc){
			flex-basis:calc(720px - (217px * 0.5431));
			flex-grow: 1;
			flex-shrink: 0;
			max-width: 760px;
			padding: 0 40px 0 0;
			margin: 0;
			text-align: left;
			.ie &{
				flex-basis: calc(55% - 40px);
			}
		}
		.section2-post-list__tab{
			ul{
				display:flex;
				margin: -79px 10px 18px;
				border-bottom: 2px solid #ddd;
				text-align: left;
				vertical-align: center;
				@include breakpoint($pc){
					max-width:720px;
					margin:-80px 0 47px;
				}
				@include breakpoint($mdw){
					margin:-95px 0 47px;
				}
				li{
					position:relative;
					flex-basis: 50%;
					flex-shrink: 0;
					flex-grow: 0;
					min-height:100px;
					padding:16px 20px 10px;
					color: #666666;
					line-height: 125%;
					font-size: 1.125rem;
					text-align: center;
					cursor: pointer;
					@include breakpoint($md){
						flex-basis: 220px;
						flex-grow: 1;
						flex-shrink: 1;
						min-width: 210px;
						font-size: 24px;
						font-weight: 700;
						padding:20px 20px 5px; 
					}
					@include breakpoint($pc){
						padding:30px 20px 5px;
					}
					&::after{
						content: '';
						display: block;
						position:absolute;
						top:50px;
						right:0;
						width:1px;
						height:20px;
						background-color: #dddddd;
						@include breakpoint($mdw){
							top:49px;
						}
						@include breakpoint($pc){
							top:55px;
						}
					}
					&:last-child{
						@include breakpoint($md){
							flex-basis: 490px;
							flex-grow: 1;
							flex-shrink: 1;
							padding:20px 20px 5px; 
						}
						@include breakpoint($pc){
							padding:30px 20px 5px 30px;
							&.align-cc{
								justify-content: left;
							}
						}
						&::after{
							display: none;
						}
					}
					&.active, &:focus, &:hover{
						font-weight: 700;
						color: #0080c6;
						&::before{
							position:absolute;
							bottom:-2px;
							left:0;
							content: '';
							display: block;
							height:5px;
							width:calc(100%);
							background-color: #0080c6;
						}
					}
					.button{
						vertical-align:middle;
					}
				}
			}
		}
		.section2-post-list__cont{
			text-align: left;
			padding:0 10px;
			@include breakpoint($pc){
				padding:0;
			}
			.cont{
				text-align: center;
				display: none;
				@include breakpoint($md){
					text-align: left;
				}
				&.active{
					display: block;
				}
				article{
					text-align: left;
				}
				.cont__main-post{
					display: block;
					background-color: #f0f0f0;
					padding:20px;
					@include breakpoint($pc){
						padding: 30px;
					}
					a{
						display: flex;
						img{
							display: none;
							@include breakpoint($md){
								display: block;
								margin-right: 38px;
								flex-basis: 210px;
								height:164px;
								flex-shrink: 0;
							}
						}
						.txt-box{
							@include breakpoint($pc){
								flex-basis: calc(100% - 210px - 38px);
							}
							h4{
								margin-bottom: 10px;
								font-size: 1.25rem;
								font-weight: 700;
								line-height: 128%;
							}
							span{
								display: block;
								font-size: 1rem;
								&.post-desc{
									margin-bottom: 10px;
								}
							}
						}
					}
				}
				.cont__sub-post{
					article{
						margin-top: 20px;
						padding-bottom: 20px;
						border-bottom: 1px solid #ddd;
						a{
							display: block;
						}
						&:last-child{
							border:0;
						}
						h4{
							font-size: 1.25rem;
							font-weight: 700;
							margin-bottom: 10px;
							line-height: 120%;
						}
						.post-desc{
							display: none;
							@include breakpoint($md){
								display: block;
								margin-bottom: 10px;
							}
						}
					}
				}
			}
			.upload-date{
				position:relative;
				display: block;
				padding-left: 24px;
				&::before{
					position:absolute;
					top:3px;
					left:0;
					content: '';
					display: inline-block;
					width:18px;
					height:20px;
					background:url(/static/fhkeng/img/ico/ico_time.svg) 0 0 no-repeat;
				}
			}
		}
		.see-all{
			display: inline-block;
			border: 1px solid $black;
			border-radius: 20px;
			padding:2px 12px 3px 16px;
			font-size: 16px;
			font-weight: 700;
			margin:40px auto 0;
			&::after{
				@include font-ico("\f1df");
				margin: 0 0 1px 8px;
				font-size: 18px;
			}
		}
	}
	.section2-menu{
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		padding:0 20px;
		margin: 60px 0 0;
		.ie &{
			flex-basis: calc(100% - 40px);
		}
		@include breakpoint($pc){
			flex-basis: calc(600px - (217px *0.4573));
			flex-grow: 1;
			flex-shrink: 0;
			max-width:640px;
			padding:0 0 0 40px;
			.ie &{
				flex-basis: calc(45% - 40px);
			}
		}
		.section2-menu__list{
			padding: 0;
			margin: 40px 0;
			&:nth-of-type(1){
				margin: 0;
			}
			h3{
				font-size: 1.5rem;
				font-weight: 700;
			}
			.sub-tit{
				display: block;
				margin-bottom: 14px;
				font-size: 0.875rem;
				color: #666666;
			}
			>div{
				display: block;
				padding:35px 20px;
				background-color: pink;
				border-radius:0.825rem 0;
				@include breakpoint($pc){
					padding:50px 20px;
				}
				ul{
					display: flex;
					flex-wrap: wrap;
					text-align: center;
					li{
						flex-basis: 50%;
						margin-top: 25px;
						font-size: 1rem;
						letter-spacing: -0.5px;
						&:nth-child(1), &:nth-child(2){
							margin-top: 0;
						}
						@include breakpoint($md){
							flex-basis: 25%;
							margin-top: 0;
						}
						a{
							display: block;
							color:$white;
							&:hover, &:focus{
								color: #00baff;
							}
							&::before{
								content: '';
								display: block;
								width:40px;
								height:40px;
								margin:0 auto 10px;
								background:url(/static/fhkeng/img/main/quick__list__item1.svg) 0 0 no-repeat;
							}
							@for $ico-num from 1 through 8{
								&.link#{$ico-num}{
									&::before{
										background:url(/static/fhkeng/img/main/quick__list__item#{$ico-num}.svg);
									}
									&:hover, &:focus{
										&::before{
											background:url(/static/fhkeng/img/main/quick__list__item#{$ico-num}_active.svg);
										}
									}
								}
							}
						}
					}
				}
				&.menu1__link{
					background:url(/static/fhkeng/img/main/section2_img2.jpg) center /cover no-repeat;
				}
				&.menu2__link{
					background:url(/static/fhkeng/img/main/section2_img3.jpg) center /cover no-repeat;
				}
			}
			&.finance-btn{
				padding: 31px;
				border-radius: 0.625rem;
				border: 0.063rem solid #95a6b8;
				color: #666666;
				font-size: 0.875rem;
				background: #edeff2 url(/static/fhkeng/img/main/section2-button-bg_m@2x.png) calc(100% - 21px) 80px no-repeat;
				background-size: 87px 55px;
				@include breakpoint($pc){
					font-size: 1.125rem;
				}
				@include breakpoint($md){
					background-size:108px 68px;
					background-image: url(/static/fhkeng/img/main/section2-button-bg.png);
					background-position:calc(100% - 25px) 30px;
				}
				a{
					display:block;
					br{
						@include breakpoint($md){
							display: none;
						} 
					}
					strong{
						display: block;
						font-size: 1.5rem;
						font-weight: 700;
						color: #000000;
						margin-bottom: 4px;
						@include breakpoint($pc){
							margin-bottom:0;
							font-size: 1.75rem;
						}
						&::after{
							@include font-ico("\e409");
							font-size: 25px; 
							@include breakpoint($pc){
								font-size: 28px;
							}
						}
					}
				}
			}
		}
	}
}

#section3{
	background:url(/static/fhkeng/img/main/section3_bg.jpg) 20% center/ cover no-repeat;
}
.section3-set{
	@include list-reset;
	margin: 0 auto;
	padding:50px 20px;
	color:$white;
	@include breakpoint($pc){
		display: flex;
		flex-wrap: wrap;
		background-position: center center;
		max-width: 1400px;
		align-items:center;
		padding:100px 0 70px;
		align-items: initial;
	}
	.section3-aboutus{
		@include breakpoint($pc){
			flex-basis: 50%;
			flex-grow: 1;
			flex-shrink: 0;
			max-width: 760px;
			flex-basis:calc(720px - (217px * 0.5431));
			padding: 0 40px 0 0;
		}
		h2{
			font-weight: 400;
			strong{
				font-weight: 700;
			}
			@include breakpoint($pc){
				font-size: 3rem;
				font-weight: 400;
				strong{
					font-weight: 600;
				}
			}
		}
		.aboutus-desc{
			font-size: 0.875rem;
			line-height: 109%;
			@include breakpoint($pc){
				font-size: 1.125rem;
				line-height: 140%;
			}
		}
		.section3-aboutus-quick{
			margin-top: 30px;
			ul{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				li{
					flex-basis: 50%;
					flex-grow: 0;
					flex-shrink: 0;
					white-space: nowrap;
					text-align: center;
					margin-bottom: 30px;
					@include breakpoint($md){
						flex-basis: 120px;
						margin-bottom: 0;
					}
					@include breakpoint($xs){
						white-space: pre-wrap;

					}
					a{
						display: block;
						color:$white;
						&::before{
							content: '';
							display: block;
							width:100px;
							height:100px;
							border-radius: 50%;
							margin:0 auto 15px;
							background:#fff url(/static/fhkeng/img/main/aboutus__list__ico1.svg) 50% no-repeat;
							background-size: 50px;
							@include breakpoint($pc){
								width:120px;
								height:120px;
								background-size: 55px 55px;
							}
						}
						&.aboutus-link2{
							&::before{
								background:#fff url(/static/fhkeng/img/main/aboutus__list__ico2.svg) 50%/ 50px 50px no-repeat;
								@include breakpoint($pc){
									background-size: 55px 55px;
								}
							}
						}
						&.aboutus-link3{
							&::before{
								background:#fff url(/static/fhkeng/img/main/aboutus__list__ico3.svg) 50%/ 50px 50px no-repeat;
								@include breakpoint($pc){
									background-size: 55px 55px;
								}
							}
						}
						&.aboutus-link4{
							&::before{
								background:#fff url(/static/fhkeng/img/main/aboutus__list__ico4.svg) 50%/ 50px 50px no-repeat;
								@include breakpoint($pc){
									background-size: 55px 55px;
								}
							}
						}
					}
				}
			}
		}
	}
	.section3-visual-set{
		position:relative;
		@include breakpoint($pc){
			flex-basis: 50%;
			flex-grow: 1;
			flex-shrink: 0;
			max-width:640px;
			min-width: 30px;
			padding-left:40px;
			flex-basis: calc(600px - (217px *0.4573));
		}
		@include breakpoint($mdw){
			margin-top:50px;
		}
		.section3-visual-quick{
			position: absolute;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width:100%;
			left:0;
			bottom: -50px;
			@include breakpoint($pc) {
				width: calc(100% - 40px);
				left: 40px;
				bottom: 10px;
			}.section3__dots{
				height: 4px;
				li {
					display: inline-block;
					margin-right: 6px;
					button {
						position: relative;
						width: 40px;
						height: 4px;
						transition: all 2s ease;
						overflow: hidden;
						background-color: $white;
						@include breakpoint($md) {
							width: 70px;
						}
					}
					&.slick-active button::after {
						position: absolute;
						top: 0;
						left: 0;
						content: "";
						display: block;
						height: 4px;
						background-color: #a50025;
						animation: visual-button 2s;
						animation-fill-mode: forwards;
						animation-delay: 1s;
					}
				}
			}
			.section3-ctrl{
				>button{
					width:30px;
					height:30px;
					background:30px 30px no-repeat;
				}
				.prev{
					left:0;
					background:url(/static/fhkeng/img/main/slide_prev.svg);
				}
				.next{
					margin-left:5px;
					background: url(/static/fhkeng/img/main/slide_next.svg);
				}
				.section3-ctrl__play{
					display: inline-block;
					margin-left: 5px;
					position:relative;
					.pause{
						display: block;
						width:30px;
						height:30px;
						background: url(/static/fhkeng/img/main/slide_pause_section3.svg) ;
					}
					.play{
						display:none;
						width:30px;
						height:30px;
						background: url(/static/fhkeng/img/main/slide_play_section3.svg);
					}
				}
			}
		}
		.section3-visual__list{
			margin: 20px auto 60px;
			div{
				.item{
					position:relative;
					img{
						border-radius: 10px 0;
						width:100%;
						&.slide_pc{
							display: none;
						}
						&.slide_m{
							display: block;
						}
						@include breakpoint($lg){
							&.slide_pc{
								display: block;
							}
							&.slide_m{
								display: none;
							}
						}
					}
				}
			}
			@include breakpoint($mdw){
				.slick-list{
					margin: 0 -10px;
				}
				.slick-slide{
					margin: 0 10px;
				}
			}
		}
	}
}