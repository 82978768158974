//사이트 텍스트 컬러
$body-font-color    : #000;

$form-color         : $body-font-color;

// search
$sh-bg-color        : #e7edf4;

$bd-color           : #dadada;//보더 컬러
$form-bd-color      : #9d9d9d;
$input-focus-shadow : #008fd4;

$color-blue         : #005aab;
$point              : #2c428e;

$gray               : #728496;
$deepgray           : #333;
$orange             : #D8761F;
$subway6            : #c7792e;
$whiteorange        : #FDF8F4;

$green              : #009344;
$red                : #bc2222;
$lightgray          : #cfcfcf;
$whitegray          : #f1f1f1;
$trgray             : #fafafa;
$white              : #fff;
$black              : #000;
$cobalt             : #02728D;
$lagoon             : #008890;
$blinkpink          : #fa5778;
$pink               : #d0319b;
$mineShaft          : #2e363a;
$muddyWaters        : #525d64;
$blue               : #02728D;
$lightblue          : #02728D;
$skyblue            : #02728D;
$grayblue           : #02728D;
$midnightblue       : #02728D;
$lightred           : #c54804;

// $link-color      : #2e363a;
$link-color         : #333;
// btn
$btn-bg-color       : #364150;
// board
$bd-top-color       : #000;
$bd-list-head-color : #000;
$bd-list-th-bg-color: #e7edf4;
$cell-hover-color   : #f4f4f4;
$cell-title-color   : #000;
// table
$th-bg-color        : #e9eef2;
$td-bg-color        : #f2f2f2;
$dl-bg-color        : #e7edf4;
// board view color
$view-bd-color      : $bd-color;
$view-bd-top-color  : #000;
$view-head-bg-color : #e7edf4;
$view-head-color    : #000;
// background       = color
$bg-gray            : #eff2f7;
$txt-gray           : #959CA1;
// faq
$faq-Q-dt-bgcolor   : #fcfcfc;
$faq-Q-bgcolor      : #005baa;
$faq-A-bgcolor      : #0c9ebd;

.color-point{
	color: $point !important;
}