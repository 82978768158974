@import '../../../commons/scss/font-material';
// font size
$browser-context: 16; // Default html font size 설정
$html-font-size	: 16px; // html font size 설정
$body-font-size : 1.125rem; // 18px
$fz-xs			: 0.875rem; // 14px
$btn-font-size : 1.125rem;  // 18px
$form-height   : 40px; // 폼, 폼에 들어가는 버튼 높이


$mobile  : 360px;	//
$tablet  : 750px;	//
$pc      : 1200px;	//pc 최소 사이즈 : 디자이너 및 클라이언트 협의에 따라
$pc-wide : 1400px;	//pc 최대 사이즈
$xs                 : max-width $mobile - 1;
$sm                 : max-width $tablet - 1;
$md                 : $tablet;
// 임시
$smd 				: max-width  $pc - 1;
$mdw             	: $tablet $pc - 1;
$lg                 : $pc;
$xl                 : $pc-wide;


$mdwidth : 1220;



// board list color
$bd-topwidth : 2px;
$bd-top-color : #000;

// input
$form-height : 40px;
// button
$btn-height : $form-height;

$m1:16px;
$m2:25px;
$m3:34px;
// input
$formHeight         : 40px;



$font-set		: 'Open Sans', verdana, sans-serif, "AppleGothic","dotum",Helvetica,Arial,sans-serif, 'Nunito Sans', sans-serif;
$heading-font	: 'Open Sans', sans-serif;


b,
strong {
	font-weight: 600;
}
.a{
	text-overflow:ellipsis;
	overflow:hidden;
	white-space:nowrap;
}